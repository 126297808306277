import { CentredContentBlock } from '@/components/blocks/CentredContentBlock'
import { ImageAndText } from '@/components/blocks/ImageAndText'
import styled from 'styled-components'

const StyledOurSciencePage = styled.div`
  #our-science {
    padding-top: 126px;
    padding-bottom: 126px;

    @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
      padding-top: 60px;
      padding-bottom: 60px;
    }

    p {
      max-width: 800px;
      margin-top: 0;
      margin-bottom: 0;
      font-size: ${(p) => p.theme.v2.typography.display.xs.fontSize};
      line-height: ${(p) => p.theme.v2.typography.display.xs.lineHeight};
      font-family: ${(p) => p.theme.v2.font.nantes};
      color: ${(props) => props.theme.v2.onSurface.text};

      @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
        font-size: ${(p) => p.theme.v2.typography.body.xl.fontSize};
        line-height: ${(p) => p.theme.v2.typography.body.xl.lineHeight};
      }
    }
  }

  #research-updates {
    display: flex;
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;

    h2 {
      font-size: ${(p) => p.theme.v2.typography.display.xxs.fontSize};
      line-height: ${(p) => p.theme.v2.typography.display.xxs.lineHeight};
      font-family: ${(p) => p.theme.v2.font.nantes};
      color: ${(props) => props.theme.v2.onSurface.text};
      margin-bottom: 60px;
      text-align: center;
      max-width: 400px;

      @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
        font-size: ${(p) => p.theme.v2.typography.body.xl.fontSize};
        line-height: ${(p) => p.theme.v2.typography.body.xl.lineHeight};
        margin-bottom: 32px;
      }
    }

    > div {
      max-width: none;
    }
  }

  #scientific-advisory-board {
    > div {
      > p {
        font-size: ${(p) => p.theme.v2.typography.display.xxs.fontSize};
        line-height: ${(p) => p.theme.v2.typography.display.xxs.lineHeight};
        font-family: ${(p) => p.theme.v2.font.nantes};
        color: ${(props) => props.theme.v2.onSurface.text};
        margin-bottom: 60px;
        margin-top: 24px;
        text-align: center;

        @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
          font-size: ${(p) => p.theme.v2.typography.body.xl.fontSize};
          line-height: ${(p) => p.theme.v2.typography.body.xl.lineHeight};
          margin-bottom: 32px;
        }
      }

      > h2 {
        font-size: ${(p) => p.theme.v2.typography.body.xs.fontSize};
        line-height: ${(p) => p.theme.v2.typography.body.xs.lineHeight};
        font-family: ${(p) => p.theme.v2.font.lotaGrotesqueRegular};
        color: ${(props) => props.theme.v2.onSurface.text};

        @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
          font-size: ${(p) => p.theme.v2.typography.body.xxs.fontSize};
          line-height: ${(p) => p.theme.v2.typography.body.xxs.lineHeight};
        }
      }
    }
  }

  #zoe-apps {
    > div {
      p {
        font-size: ${(p) => p.theme.v2.typography.display.xxs.fontSize};
        line-height: ${(p) => p.theme.v2.typography.display.xxs.lineHeight};
        font-family: ${(p) => p.theme.v2.font.nantes};
        color: ${(props) => props.theme.v2.onSurface.text};
        text-align: center;
        max-width: 620px;
        margin-top: 0;
        margin-bottom: 0;

        @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
          font-size: ${(p) => p.theme.v2.typography.body.xl.fontSize};
          line-height: ${(p) => p.theme.v2.typography.body.xl.lineHeight};
        }
      }

      h4 {
        font-size: ${(p) => p.theme.v2.typography.body.m.fontSize};
        line-height: ${(p) => p.theme.v2.typography.body.m.lineHeight};
        font-family: ${(p) => p.theme.v2.font.lotaGrotesqueLight};
        color: ${(props) => props.theme.v2.onSurface.text};
        text-align: center;
        max-width: 770px;
        margin-bottom: 0;

        @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
          font-size: ${(p) => p.theme.v2.typography.body.xs.fontSize};
          line-height: ${(p) => p.theme.v2.typography.body.xs.lineHeight};
        }
      }
    }

    .three-column-feature {
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 0;

      > div {
        h3 {
          font-size: ${(p) => p.theme.v2.typography.body.s.fontSize};
          line-height: ${(p) => p.theme.v2.typography.body.s.lineHeight};
          font-family: ${(p) => p.theme.v2.font.nantes};
          color: ${(props) => props.theme.v2.onSurface.text};
          text-align: center;
          margin-bottom: 12px;
        }

        a:hover {
          h3 {
            color: ${(props) => props.theme.v2.interactive.bright};
          }
        }

        p {
          font-size: ${(p) => p.theme.v2.typography.body.xs.fontSize};
          line-height: ${(p) => p.theme.v2.typography.body.xs.lineHeight};
          font-family: ${(p) => p.theme.v2.font.lotaGrotesqueLight};
          color: ${(props) => props.theme.v2.onSurface.text};
          text-align: center;

          @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
            font-size: ${(p) => p.theme.v2.typography.body.xxs.fontSize};
            line-height: ${(p) => p.theme.v2.typography.body.xxs.lineHeight};
          }
        }
      }
    }
  }
`

const StyledImageAndText = styled.div`
  .image-and-text-right {
    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      padding: 24px;
    }
  }

  .text-description-container {
    max-width: 450px;

    p {
      font-size: ${(p) => p.theme.v2.typography.body.s.fontSize};
      line-height: ${(p) => p.theme.v2.typography.body.s.lineHeight};
      font-family: ${(p) => p.theme.v2.font.lotaGrotesqueLight};
      color: ${(props) => props.theme.v2.onSurface.text};
      margin-bottom: 32px;

      @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
        font-size: ${(p) => p.theme.v2.typography.body.xs.fontSize};
        line-height: ${(p) => p.theme.v2.typography.body.xs.lineHeight};
        margin-bottom: 24px;
      }
    }

    .text-description {
      .container {
        a.button {
          margin-top: 0;
        }
      }
    }

    @media (max-width: ${(props) => props.theme.breakpoints.tablet}) {
      max-width: 100%;

      .text-description {
        .container {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-bottom: 0;
        }
      }
    }

    h3 {
      font-size: ${(p) => p.theme.v2.typography.display.s.fontSize};
      line-height: ${(p) => p.theme.v2.typography.display.s.lineHeight};
      font-family: ${(p) => p.theme.v2.font.nantes};
      color: ${(props) => props.theme.v2.onSurface.text};
      margin-bottom: 16px;
      max-width: 320px;

      @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
        text-align: center;
        font-size: ${(p) => p.theme.v2.typography.body.xl.fontSize};
        line-height: ${(p) => p.theme.v2.typography.body.xl.lineHeight};
        margin-bottom: 12px;
      }
    }
  }
`

const renderBlock = (record) => {
  switch (record.__typename) {
    case 'ImageAndTextBlockRecord':
      return (
        <StyledImageAndText key={record.id}>
          <ImageAndText
            record={record}
            containerStyle={{
              height: 'auto',
              width: '100%',
              ...(record.backgroundColour?.hex && { backgroundColor: record.backgroundColour.hex }),
              padding: '80px 60px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            imageSubContainerStyle={{ height: 'auto', width: '100%', padding: '0 24px', maxWidth: '450px' }}
            textSubContainerStyle={{
              height: 'auto',
              paddingRight: '60px',
            }}
          />
        </StyledImageAndText>
      )
    case 'CentredContentBlockRecord':
      return <CentredContentBlock record={record} key={record.id} />
  }
}

export const OurSciencePageContent = ({ content }) => {
  return <StyledOurSciencePage>{(content || []).map(renderBlock)}</StyledOurSciencePage>
}
