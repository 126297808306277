import { StructuredText } from 'react-datocms'
import styled from 'styled-components'
import { OurSciencePageHeroImageRecord } from 'types'

const StyledOurSciencePageHero = styled.div<{ $backgroundImageSrc: string }>`
  position: relative;
  height: 520px;

  background-image: url(${(props) => props.$backgroundImageSrc});
  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (min-width: ${(p) => p.theme.breakpoints.largeDesktop}) {
    height: 660px;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.tablet}) {
    height: 360px;
  }

  @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
    height: 200px;
  }
`

const StyledOurSciencePageHeroOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  h1 {
    font-size: ${(p) => p.theme.v2.typography.display.xl.fontSize};
    line-height: ${(p) => p.theme.v2.typography.display.xl.lineHeight};
    font-family: ${(p) => p.theme.v2.font.nantes};
    color: white;
    text-align: center;

    @media screen and (max-width: ${(p) => p.theme.breakpoints.mobile}) {
      font-size: ${(p) => p.theme.v2.typography.display.xxs.fontSize};
      line-height: ${(p) => p.theme.v2.typography.display.xxs.lineHeight};
    }
  }
`

type OurSciencePageHeroProps = {
  heroImage: OurSciencePageHeroImageRecord
  heroImageOverlay?: {
    value
  }
}

export const OurSciencePageHero: React.FC<OurSciencePageHeroProps> = ({ heroImage, heroImageOverlay }) => {
  return (
    <>
      {heroImage.responsiveImage?.src && (
        <StyledOurSciencePageHero $backgroundImageSrc={heroImage.responsiveImage.src}>
          <StyledOurSciencePageHeroOverlay>
            <StructuredText data={heroImageOverlay} />
          </StyledOurSciencePageHeroOverlay>
        </StyledOurSciencePageHero>
      )}
    </>
  )
}
