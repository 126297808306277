import styled from 'styled-components'
import Link from 'next/link'

type ScienceSubNavigationProps = {
  selected: string
}

const StyledScienceSubNavigation = styled.nav`
  background-color: ${(props) => props.theme.v2.surface.cream300};
  margin-top: 88px;
  padding: 20px 0 14px;

  & h4 {
    margin: 0;
    text-transform: uppercase;
    font-size: ${(props) => props.theme.v2.typography.body.xs.fontSize};
    line-height: ${(props) => props.theme.v2.typography.body.xs.lineHeight};
    letter-spacing: 2.4px;
    padding: 7px 32px 7px 0;
    border-right: 1px solid ${(props) => props.theme.v2.onSurface.subdued.border};
  }

  @media screen and (max-width: ${(props) => props.theme.breakpoints.tablet}) {
    & h4 {
      padding: 7px 16px;
    }
  }
`

const StyledContainer = styled.div`
  width: 100%;
  max-width: 1008px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0;
  }
`

const StyledScroll = styled.div`
  overflow: scroll;
  white-space: nowrap;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    margin: 0 24px 0 0;
  }
`

const StyledNavList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  list-style-type: none;
  li {
    margin-left: 32px;
    a {
      color: ${(props) => props.theme.v2.secondary.action};
      transition: color 400ms ease-in;
      font-family: ${(props) => props.theme.v2.font.lotaGrotesqueLight};
      font-size: ${(props) => props.theme.v2.typography.body.xs.fontSize};
      line-height: ${(props) => props.theme.v2.typography.body.xs.lineHeight};
      &:hover {
        color: ${(props) => props.theme.v2.interactive.bright};
      }
    }
    &.active {
      a {
        color: ${(props) => props.theme.v2.interactive.bright};
      }
    }
  }
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    li {
      a {
        font-size: ${(props) => props.theme.v2.typography.body.xxs.fontSize};
        line-height: ${(props) => props.theme.v2.typography.body.xxs.lineHeight};
      }
      &:last-child {
        a {
          padding-right: 24px;
        }
      }
    }
  }
`

const StyledNavButton = styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  right: 10px;
  top: 9px;
  transform: rotate(180deg);
  background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.15674 2.33643L0.712016 7.78115C0.594859 7.8983 0.594858 8.08825 0.712016 8.20541L6.15674 13.6501' stroke='%23445DC3' stroke-linecap='round' stroke-width='2px' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  cursor: pointer;
  display: none;
  @media screen and (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    display: block;
  }
`

const NextButtonClickHandler = () => {
  const ScrollNavigationEl = document.getElementById('ScrollNavigation')
  if (ScrollNavigationEl !== null) {
    ScrollNavigationEl.scrollBy({
      top: 0,
      left: +100,
      behavior: 'smooth',
    })
  }
}

const links = [
  {
    title: 'Overview',
    slug: '/whitepapers/overview',
  },
  {
    title: 'Studies',
    slug: '/our-studies',
  },
  {
    title: 'Metabolic response',
    slug: '/whitepapers/metabolic-response',
  },
  {
    title: 'Gut microbiome',
    slug: '/whitepapers/gut-microbiome',
  },
  {
    title: 'Research updates',
    slug: '/our-science#research-updates',
  },
  {
    title: 'Scientists',
    slug: '/our-science#scientific-advisory-board',
  },
]

export const ScienceSubNavigation = ({ selected }: ScienceSubNavigationProps) => (
  <StyledScienceSubNavigation>
    <StyledContainer>
      <StyledScroll id="ScrollNavigation">
        <StyledNavList>
          {links.map(({ title, slug }) => {
            return (
              <li key={slug} className={`${selected === slug ? 'active' : ''}`}>
                <Link href={slug}>{title}</Link>
              </li>
            )
          })}
        </StyledNavList>
      </StyledScroll>
      <StyledNavButton onClick={NextButtonClickHandler} />
    </StyledContainer>
  </StyledScienceSubNavigation>
)
