import { Footer } from '@/components/elements/footer/Footer'
import { Header } from '@/components/elements/header/Header'
import { ScienceSubNavigation } from '@/components/elements/science/ScienceSubNavigation'
import Head from 'next/head'
import { OurSciencePageRecord } from 'types'
import { OurSciencePageContent } from './OurSciencePageContent'
import { OurSciencePageHero } from './OurSciencePageHero'

type OurSciencePageProps = {
  page: OurSciencePageRecord
}

export const OurSciencePage: React.FC<OurSciencePageProps> = ({ page }) => {
  return (
    <>
      <Head>
        <title>Our Science</title>
      </Head>

      <Header backgroundColor="white" />

      <ScienceSubNavigation selected="/our-science" />

      <main id="main-content">
        <OurSciencePageHero heroImage={page.heroImage} heroImageOverlay={page.heroImageOverlay} />
        <OurSciencePageContent content={page.content} />
      </main>

      <Footer />
    </>
  )
}
