import { OurSciencePage } from '@/components/pages/our-science/OurSciencePage'
import { getOurSciencePage } from '@/graphql/OurScience'
import { GetStaticProps } from 'next'

export const getStaticProps: GetStaticProps = async ({ preview }) => {
  const page = await getOurSciencePage(preview)

  if (page) {
    return { props: { page: page } }
  }

  return {
    notFound: true,
  }
}

export default OurSciencePage
